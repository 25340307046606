!function ($) {
    "use strict";

    var Auth = function () {
    };

    /* Init Autorefresh */
    Auth.prototype.initAutorefresh = function () {
        const timeout = parseFloat(process.env.MIX_SESSION_LIFETIME) * 60000;  // 60000 ms = 1 minute

        setTimeout(function () {
            window.location.href = process.env.MIX_APP_URL;
        }, timeout);
    };

    $.Auth = new Auth;
    $.Auth.Constructor = Auth;

    /* Initializing */
    $.Auth.initAutorefresh();

}(window.jQuery);
